<template>
  <div class="order_page">
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">{{ language.TransactionList }}</div>
      <div style="display: flex; margin-top: 30px">
        <!-- 币种 -->
        <div class="input_box input_box3">
          <el-select v-model="Currency" :placeholder="language.Currency">
            <el-option
              v-for="item in Currencylist"
              :key="item.type_name"
              :label="item.type_name"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 搜索 -->
        <div class="input_box">
          <el-button type="primary" id="SearchBtn" @click="SearchBtn()">{{
            language.Search
          }}</el-button>
        </div>
        <!-- 重置 -->
        <div class="input_box">
          <el-button type="danger" id="ResetBtn" @click="ResetBtn()">{{
            language.Reset
          }}</el-button>
        </div>
      </div>
      <div>
        <el-table
          :data="tableData"
          border
          style="width: 100%; margin-top: 20px"
          stripe
          v-loading="loading"
          :header-cell-style="{ background: '#fafafa' }"
        >
          <!-- 提现订单 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.Merchantordernumber"
            min-width="10%"
          >
          </el-table-column>
          <el-table-column
            prop="my_order_no"
            :label="'EPAY ' + language.orderNumber"
            min-width="10%"
          >
          </el-table-column>
          <!-- 主网络 -->
          <!-- <el-table-column
            prop="appid"
            :label="language.Apply_No + '/appId'"
            min-width="10%"
          >
          </el-table-column> -->
          <!-- 姓名 -->
          <el-table-column prop="account_name" :label="language.name" min-width="10%">
          </el-table-column>
          <!-- 提现地址 -->
          <el-table-column :label="language.Withdrawalaccount" min-width="10%">
            <template slot-scope="scope">
              <!-- <span v-if="scope.row.transfer_from_id == 6">{{
                scope.row.account_no
              }}</span> -->
              <!-- <span v-if="!scope.row.wallet_address">{{ scope.row.bank_code }}</span> -->
              <span v-if="scope.row.account_no">{{ scope.row.account_no }}</span>
            </template>
          </el-table-column>
          <!-- 币种 -->
          <el-table-column prop="currency" :label="language.Currency" min-width="10%">
          </el-table-column>
          <!-- 提现金额 -->
          <el-table-column :label="language.Cashwithdrawalamount" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.money }} </span>
              <span>{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 提现手续费 -->
          <el-table-column :label="language.Withdrawalhandlingfee" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.service_fee }} </span>
              <span>{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 商户结算金额 -->
          <el-table-column :label="language.Merchantsettlementamount" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.settle_amount }} </span>
              <span>{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 申请时间 -->
          <el-table-column
            prop="create_time"
            :label="language.Applicationtime"
            min-width="10%"
          >
          </el-table-column>
          <!-- 转账时间 -->
          <el-table-column :label="language.Transfertime" min-width="10%">
            <template slot-scope="scope">
              <span v-if="scope.row.transfer_server_time">{{
                $getNowFormatDate.chagetime(scope.row.transfer_server_time)
              }}</span>
              <span v-else></span>
            </template>
          </el-table-column>
          <!-- 提现状态 -->
          <el-table-column :label="language.State" min-width="10%">
            <template slot-scope="scope">
              <!-- 待审核 -->
              <span v-if="scope.row.transfer_state == 1">{{
                language.Tobereviewed
              }}</span>
              <!-- 已审核待转款 -->
              <span style="color: #dd9410" v-if="scope.row.transfer_state == 2">{{
                language.Approvedpendingtransfer
              }}</span>
              <!-- 已拒绝 -->
              <span style="color: red" v-if="scope.row.transfer_state == 3">{{
                language.Rejected
              }}</span>
              <span style="color: #009b05" v-if="scope.row.transfer_state == 4">{{
                language.Completed
              }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column :label="language.callbacknotification" min-width="10%">
            <template slot-scope="scope">
              <span
                style="color: #009b05"
                v-if="scope.row.is_notify && scope.row.transfer_state > 1"
                >{{ language.success }}</span
              >
              <span
                style="color: red"
                v-if="!scope.row.is_notify && scope.row.transfer_state > 1"
                >{{ language.bedefeated }}</span
              >
              <span v-if="scope.row.transfer_state == 1">/</span>
            </template>
          </el-table-column> -->
          <!-- 备注remarks -->
          <!-- <el-table-column prop="result_info" :label="language.remarks" min-width="10%">
            <template slot-scope="scope">
              <span v-if="scope.row.result_info">
                {{ scope.row.result_info }}
              </span>
              <span v-else> / </span>
            </template>
          </el-table-column> -->
        </el-table>
        <div class="paging">
          <el-pagination
            v-loading="loading"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            current-page.sync="5"
            :page-sizes="[10, 15, 20, 25, 30]"
            :page-size="limit"
            layout="sizes, prev, pager, next"
            :total="pages"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import payment from "../../common/json/payment.json";
import { transfer } from "../../api/index";
export default {
  data() {
    return {
      languagetype: "",
      language: "",
      loading: false,
      tableData: [],
      Currencylist: [
        {
          type_name: "",
          value: "",
        },
      ],
      Currency: "",
      pages: 1,
      page: 1,
      limit: 15,
    };
  },
  created() {
    this.changelanguage();
    this.init();
    this.Currencylist = [...this.Currencylist, ...payment.otherCurrencylist];
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (this.userinfo.currency != "all") {
      for (let i = this.Currencylist.length - 1; i >= 0; i--) {
        if (this.Currencylist[i].value != this.userinfo.currency) {
          this.Currencylist.splice(i, 1);
        }
      }
    }
    if (this.Currencylist[0]) {
      this.Currency = this.Currencylist[0];
    }
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
  },
  methods: {
    ResetBtn() {
      this.Currency = this.Currencylist[0];
      this.init();
    },
    SearchBtn() {
      this.init();
      // console.log(this.Currency);
    },
    init() {
      this.loading = true;
      let data = {
        page: this.page,
        limit: this.limit,
      };
      if (this.Currency.value != -1) {
        data.currency = this.Currency.value;
      }
      data.type = 1;
      transfer(data).then((res) => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pages = res.data.count;
      });
    },
    // 切换每页条数
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.init();
    },
    //选择页数
    handleCurrentChange(val) {
      this.page = val;
      this.init();
    },
    // 获取语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
      this.Currencylist[0].type_name = this.language.whole;
    },
  },
};
</script>
<style scoped>
.order_page {
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.order_page > div {
  min-height: calc(100vh - 129px);
}
.page_title {
  font-size: 26px;
  font-weight: 600;
}
.input_box {
  margin-right: 30px;
}

.input_box3 {
  width: 200px;
}
* >>> .el-table--border {
  border-bottom: 1px solid #efefefef;
}
.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}
* /deep/ .el-table .cell {
  white-space: nowrap;
}
</style>
